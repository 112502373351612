<template>
  <div>
    <div :class="{ 'back-color': false }">
      <!-- 导航 -->
      <div class="nav-title">
        <van-icon name="arrow-left" @click="$router.go(-1)" />
        <span class="text">进货订单</span>

        <van-icon name="ellipsis" />
      </div>
      <div class="box-layout">
        <!-- 功能分类 -->
        <div class="process-clsas">
          <div
            class="options-title"
            v-for="(item, index) in titleList"
            :key="index"
            @click="onOptions(item.key)"
          >
            <span
              :class="{ 'title-bottom-show': orderStatus * 1 === item.key * 1 }"
            >
              {{ item.value }}
            </span>
            <div
              :class="{ 'title-bottom-show': orderStatus * 1 === item.key * 1 }"
              class="title-bottom"
            ></div>
          </div>
        </div>
        <div>
          <OrderCard
            ref="orderCard"
            :orderType="orderType"
            :orderStatus="orderStatus"
          >
          </OrderCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderCard from "@/components/order-card/order-card.vue";
import { getOrderTitle } from "@/api/order";

export default {
  name: "all-order",
  components: {
    OrderCard,
  },
  data() {
    return {
      orderStatus:
        this.$route.query.orderStatus === undefined
          ? 0
          : this.$route.query.orderStatus,
      titleList: [],
      orderType: 2,
    };
  },
  mounted() {
    this.getOrderTitle();
  },
  methods: {
    setOrderType(num) {
      // 点击普通订单和公益订单触发
      this.orderType = num;
      this.getOrderTitle();
      this.onOptions(0);
    },
    async getOrderTitle() {
      //获取订单类型
      const ret = await getOrderTitle({ order_type: this.orderType });
      if (ret.code * 1 == 1) {
        this.titleList = ret.data;
      }
    },

    onOptions(index) {
      // 切换订单类型
      this.orderStatus = index;
    },
  },
};
</script>

<style lang="less" scoped>
.back-color {
  background-color: #fff;
  height: 100vh;
}

// 导航栏样式
.nav-title {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 8000;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(#fff, #f9f9f9);
  border-bottom: 1px solid #ededed;
  padding: 12px;
  font-size: 20px;
  color: #6f6f6f;

  .text {
    color: #000;
    font-weight: 500;
  }
}

// 流程分类
.box-layout {
  padding: 58px 12px 12px 12px;
}

// 分类导航
.process-clsas {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  text-align: center;

  .options-title {
    position: relative;
    width: 60px;
    padding: 12px 0;

    .title-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      width: 60px;
      display: none;
      border-bottom: 2px solid #ed301d;
    }

    .title-bottom-show {
      display: block;
      color: #ed301d;
    }
  }
}
</style>
